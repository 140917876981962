import { doQuery, doAction } from "@/api/core"
import { mapGetters } from 'vuex'
export default {
    name: "questionaire",
    data() {
        return {
            taskId: undefined,
            taskItemId: undefined,
            questionnaire: null,
            answerSheet: null
        }
    },
    mounted() {
        if(this.$route.params) {
            if(this.$route.query.taskId) {
                this.taskId = this.$route.query.taskId
            }
            if(this.$route.query.taskItemId) {
                this.taskItemId = this.$route.query.taskItemId
            }
            this.queryQuestionaires()
        }
    },
    computed: {
        ...mapGetters([
            'staffId'
        ]),

        //答题进度
        answerSheetLen() {
            const that = this
            var cnt = 0;
            if (this.answerSheet)
                this.answerSheet.forEach(function(item, index) {
                    if ((item.answer != null && item.answer != '') || item.type == that.$consts.biz.QUES_TYPES.TEXT) {
                        ++cnt
                    }
                })
            return cnt;
        },

        //是否全部答题
        answerFinished() {
            return (this.questionnaire && this.questionnaire.questions && this.answerSheetLen >= this.questionnaire.questions.length)
        }
    },
    methods: {
        onBlankInput(selectQuestionIndex, index, args) {
            const that = this
            var arr = []
            if (that.answerSheet) {
                arr = [...that.answerSheet]
            }
            arr[selectQuestionIndex].answer = arr[selectQuestionIndex].blank_answers.join('|')
            that.answerSheet = arr
        },

        queryQuestionaires(func) {
            const that = this
            doQuery({
                query_code: 'LNE00',
                param_str2: (that.taskId ? that.taskId : ''),
                param_str3: (that.taskItemId ? that.taskItemId : ''),
                page: 1,
                limit: 10
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                    //考试基本信息
                    that.questionnaire = res.data[0]
                    //如果之前没有taskitem_id，此时设置上
                    that.taskItemId = that.questionnaire.taskitem_id
                    //刷新考试题目信息
                    that.queryQuestions(that.questionnaire.paper_id, res.data[0].exam_id)
                    if(func) {
                        func()
                    }
                }
            })
        },
        queryQuestions(paperId, examId) {
            const that = this
            doQuery({
                query_code: 'LNE10',
                param_str2: (paperId ? paperId : ''),
                param_str3: (examId ? examId : ''),
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                    //考试题目信息
                    var questions = []
                    that.answerSheet = []
                    res.data.forEach((item, index) => {
                        questions.push({
                            question_id: item.question_id,
                            question: item.question,
                            score: item.score,
                            type: item.type,
                            options: [
                                {
                                    number: 'A',
                                    text: item.answer1
                                },
                                {
                                    number: 'B',
                                    text: item.answer2
                                },
                                {
                                    number: 'C',
                                    text: item.answer3
                                },
                                {
                                    number: 'D',
                                    text: item.answer4
                                },
                                {
                                    number: 'E',
                                    text: item.answer5
                                },
                                {
                                    number: 'F',
                                    text: item.answer6
                                },
                            ],
                            blank: item.type == that.$consts.biz.QUES_TYPES.BLANK ? (that.$cmyUtils.getStrCount(item.question, '()') + that.$cmyUtils.getStrCount(item.question,'（）')) : 0
                        })
                        that.answerSheet.push({
                            type:item.type,
                            question_id: item.question_id,
                            answer: (item.type == that.$consts.biz.QUES_TYPES.MULTI && item.answer)? item.answer.split('|') : item.answer,
                            blank_answers: (item.type != 2 || !item.answer ? [] : item.answer.split('|'))
                        })
                    })
                    that.$set(that.questionnaire, 'questions', questions)
                }
            })
        },

        submitQuestionnaireResultPre() {
            const that = this
            if(that.answerSheetLen == 0) {
                that.$message.error('你还没有开始答题哦')
                return
            }
            /*没回答完成，但不限时*/
            if (that.answerSheetLen > 0 && that.answerSheetLen < that.answerSheet.length) {
                that.$confirm({
                    centered: true,
                    title: '你还没有全部答完，是否暂存本次答题信息？',
                    onOk() {
                        that.submitQuestionnaireResult()
                    }
                })
            }else {
                that.submitQuestionnaireResult()
            }
        },

        submitQuestionnaireResult() {
            const that = this

            doAction({
                action_code: 'LNE0',
                param_str5: JSON.stringify({
                    staff_id: that.staffId,
                    task_id: that.taskId ? that.taskId : '',
                    taskitem_id: that.taskItemId ? that.taskItemId : '',
                    exam_id: that.questionnaire.exam_id ? that.questionnaire.exam_id : '',
                    answersheet: that.answerSheet
                })
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK ) {
                    if(res.data.errcode >= that.$consts.biz.OK) {
                        that.$message.success('提交问卷成功')
                        that.$router.replace({name: 'StudentIndex'})
                    }else {
                        that.$message.error(that.$consts.biz.FAIL_MSG)
                    }
                }else {
                    that.$message.error(that.$consts.biz.FAIL_MSG)
                }
            })
        }
    }
}
